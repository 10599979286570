import Vue from 'vue';
import Router from 'vue-router';
import NPRogress from 'nprogress';
import store from '@/store/store';
import { storeActions } from '@/store/store-types';
import PopUpListVue from '@/views/popup-list/popup-list.vue';
import ReportListVue from '@/views/report-list/report-list.vue';
import CompaniesVue from '@/views/companies/companies.vue';
import authGuard from '@/auth/auth-guard';

Vue.use(Router);

// TEMP: Organizational Engagement only has access in beta api
const adminRoles = ['PopUp Survey Admins', 'RC Tech Jedi Knights Team'];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/popups',
      name: 'popups',
      component: PopUpListVue,
      meta: {
        auth: [...adminRoles]
      },
      async beforeEnter(routeTo, routeFrom, next) {
        try {
          NPRogress.start();
          await store.dispatch(storeActions.popUp.list);
          await store.dispatch(storeActions.company.listEnabled);
          NPRogress.done();
          next();
        } catch (err) {
          NPRogress.done();
          Vue.$log.error(err);
          next({ name: 'error' });
        }
      }
    },
    {
      path: '/reports',
      name: 'reports',
      component: ReportListVue,
      meta: {
        auth: [...adminRoles]
      },
      async beforeEnter(routeTo, routeFrom, next) {
        try {
          NPRogress.start();
          await store.dispatch(storeActions.report.list);
          NPRogress.done();
          next();
        } catch (err) {
          NPRogress.done();
          Vue.$log.error(err);
          next({ name: 'error' });
        }
      }
    },
    {
      path: '/companies',
      name: 'companies',
      component: CompaniesVue,
      meta: {
        auth: [...adminRoles]
      },
      async beforeEnter(routeTo, routeFrom, next) {
        try {
          NPRogress.start();
          await store.dispatch(storeActions.company.listAll);
          NPRogress.done();
          next();
        } catch (err) {
          NPRogress.done();
          Vue.$log.error(err);
          next({ name: 'error' });
        }
      }
    },
    {
      path: '/auth-callback',
      name: 'auth-callback',
      component: () => import(/* webpackChunkName: "auth-callback" */ './views/auth/auth-callback.vue'),
      meta: {
        auth: false
      }
    },
    {
      path: '/forbidden',
      name: 'forbidden',
      component: () => import(/* webpackChunkName: "forbidden" */ './views/auth/forbidden.vue'),
      meta: {
        auth: false
      }
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import(/* webpackChunkName: "logout" */ './views/auth/logout.vue'),
      meta: {
        auth: false
      }
    },
    {
      path: '/error',
      name: 'error',
      component: () => import(/* webpackChunkName: "error" */ './views/error/error.vue'),
      props: true,
      meta: {
        auth: false
      }
    },
    {
      path: '/404',
      name: '404',
      component: () => import(/* webpackChunkName: "not-found" */ './views/404/not-found.vue'),
      props: true,
      meta: {
        auth: false
      }
    },
    {
      path: '*',
      redirect: { name: 'popups' }
    }
  ]
});

// Allow cicd testing to run without the guard. In the future, setup Auth0 credentials for CircleCI.
if (process.env.VUE_APP_OIDC_AUTHORITY !== 'not-set') {
  router.beforeEach(authGuard);
}

export default router;
