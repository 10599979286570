import Vue from 'vue';
import client from 'api-client';
import { popUpModuleActions } from './store-popup-types';

//  TODO: do we need popUpType in state?
const state = {
  items: [],
  popUpTypes: [
    {
      code: 1,
      name: 'RATING'
    },
    {
      code: 2,
      name: 'MULTIPLE'
    },
    {
      code: 3,
      name: 'TEXTAREA'
    },
    {
      code: 4,
      name: 'LINK'
    },
    {
      code: 5,
      name: 'MULTISELECT'
    },
    {
      code: 6,
      name: 'MAINTENANCE'
    }
  ],
  popUpAudienceTypes: [
    {
      code: 1,
      name: 'AllTeamMembers'
    },
    {
      code: 2,
      name: 'SpecificTeamMembers'
    },
    {
      code: 3,
      name: 'SpecificCompanies'
    }
  ]
};

const actions = {
  [popUpModuleActions.delete]({ commit }, data) {
    return client.popUp.delete(data)
      .then(res => commit('deletePopup', data.popUpId)); // results return "Deleted"; so need to use data
  },
  [popUpModuleActions.list]({ commit }) {
    return client.popUp.list()
      .then(res => commit('setPopup', res));
  },
  [popUpModuleActions.create]({ commit }, data) {
    return client.popUp.save(data)
      .then(res => commit('addPopup', res));
  },
  [popUpModuleActions.update]({ commit }, data) {
    return client.popUp.update(data)
      .then(res => commit('updatePopup', res));
  }
};

const mutations = {
  setPopup(statePassThru, data) {
    statePassThru.items = data;
  },
  addPopup(statePassThru, data) {
    statePassThru.items.push(data);
  },
  deletePopup(statePassThru, popUpId) {
    var index = statePassThru.items.findIndex(item => item.popUpId === popUpId);
    statePassThru.items.splice(index, 1);
  },
  updatePopup(statePassThru, data) {
    var index = statePassThru.items.findIndex(item => item.popUpId === data.popUpId);
    Vue.set(statePassThru.items, index, data);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
