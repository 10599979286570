const white = '#ffffff';
const black = '#000000';

export default {
  name: 'PopupViewerComponent',
  props: {
    payload: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    fontColor: null
  }),
  created() {
    this.fontColor = this.invertColor(this.payload.color);
  },
  methods: {
    openLink: (url) => {
      window.open(url, '_blank');
    },
    invertColor: function invertColor(hex) {
      if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
      }

      if (hex.length === 3) {
        hex = hex.replace(/(.)/g, '$1$1');
      }

      var r = parseInt(hex.slice(0, 2), 16);
      var g = parseInt(hex.slice(2, 4), 16);
      var b = parseInt(hex.slice(4, 6), 16);

      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? black : white;
    }
  }
};
