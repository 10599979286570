import createAuth0Client from '@auth0/auth0-spa-js';

let client = null;

export default {
  async getClient() {
    if (client === null) {
      client = createAuth0Client({
        domain: process.env.VUE_APP_OIDC_AUTHORITY,
        client_id: process.env.VUE_APP_OIDC_CLIENT_ID,
        audience: process.env.VUE_APP_OIDC_AUDIENCE,
        redirect_uri: `${window.location.origin}/auth-callback`,
        useRefreshTokens: true
      });
    }
    return client;
  }
};
