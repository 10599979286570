import client from 'api-client';
import { reportModuleActions } from './store-report-types';

const state = {
  items: []
};

// actions
const actions = {
  [reportModuleActions.list]({ commit }) {
    return client.report.list().then((res) => commit('setReport', res));
  },
  [reportModuleActions.refresh]({ commit }, data) {
    return client.report.refresh(data);
  }
};

// mutations
const mutations = {
  setReport(statePassThru, data) {
    statePassThru.items = data;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
