import AuthClient from './auth-client';
import { hasRole } from './auth-utilities';

export default {
  async loginWithRedirect(options) {
    const client = await AuthClient.getClient();
    return client.loginWithRedirect(options);
  },
  async loginWithRedirectFromIframe(options) {
    const client = await AuthClient.getClient();
    const url = await client.buildAuthorizeUrl(options);
    window.top.location.href = url;
    return url;
  },
  async logout(options) {
    const client = await AuthClient.getClient();
    await client.logout(options);
  },
  async handleRedirectCallback() {
    const client = await AuthClient.getClient();
    return client.handleRedirectCallback();
  },
  async isAuthenticated() {
    const client = await AuthClient.getClient();
    return await client.isAuthenticated();
  },
  async isAuthorized(authorizedRoles) {
    let isAuthorized = false;

    const client = await AuthClient.getClient();
    const isAuthenticated = await client.isAuthenticated();

    if (isAuthenticated) {
      const claims = await client.getUser();
      const groups = claims['https://ql.custom.openid.com/groups'] ?? [];

      isAuthorized = hasRole(authorizedRoles, groups);
    }

    return isAuthenticated && isAuthorized;
  },
  async getUser() {
    const client = await AuthClient.getClient();
    return await client.getUser();
  },
  async getTokenSilently() {
    const client = await AuthClient.getClient();
    return client.getTokenSilently();
  }
};
