import { render, staticRenderFns } from "./popup-add-edit.vue?vue&type=template&id=1c79aa56&scoped=true&"
import script from "./popup-add-edit.js?vue&type=script&lang=js&"
export * from "./popup-add-edit.js?vue&type=script&lang=js&"
import style0 from "./popup-add-edit.vue?vue&type=style&index=0&id=1c79aa56&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c79aa56",
  null
  
)

export default component.exports