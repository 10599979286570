import { storeActions } from '@/store/store-types';
import PopUpAddEditComponent from '@/components/popup-add-edit/popup-add-edit.vue';
import PopUpViewerComponent from '@/components/popup-viewer/popup-viewer.vue';

// todo, move this to service/vuex
const popUpTypes = [
  {
    code: 1,
    name: 'RATING'
  },
  {
    code: 2,
    name: 'MULTIPLE'
  },
  {
    code: 3,
    name: 'TEXTAREA'
  },
  {
    code: 4,
    name: 'LINK'
  },
  {
    code: 5,
    name: 'MULTISELECT'
  },
  {
    code: 6,
    name: 'MAINTENANCE'
  }
];

const popUpAudienceTypes = [
  {
    code: 1,
    name: 'Everyone'
  },
  {
    code: 2,
    name: 'Specific CommonIds'
  },
  {
    code: 3,
    name: 'All Leaders'
  },
  {
    code: 4,
    name: 'Specific Companies'
  }
];

export default {
  components: {
    'popup-add-edit-component': PopUpAddEditComponent,
    'popup-viewer-component': PopUpViewerComponent
  },
  data: () => ({
    showMobileSearch: false,
    addEditDialog: false,
    search: '',
    headers: [
      { text: '', value: 'data-table-expand' },
      { text: 'Id', value: 'popUpId', align: 'left' },
      { text: 'Title*', value: 'pages[0].title', align: 'left' },
      { text: 'Question Count', value: 'pages.length', align: 'left' },
      { text: 'Audience', value: 'audience4Display', align: 'left' },
      { text: 'Go-Live Date', value: 'goLiveDateTime4Display', align: 'left' },
      { text: 'Actions', value: 'action', sortable: false, align: 'left' }
    ],
    mobileHeaders: [
      { text: 'Id', value: 'popUpId', align: 'left' },
      { text: 'Question Count', value: 'pages.length', align: 'left' },
      { text: 'Title*', value: 'pages[0].title', align: 'left' },
      { text: 'Audience', value: 'audience4Display', align: 'left' },
      { text: 'Go-Live Date', value: 'goLiveDateTime4Display', align: 'left' },
      { text: 'Number of Reminders', value: 'numberOfReminders', align: 'left' },
      { text: 'Frequency of Reminders', value: 'frequencyOfRemindersInHours', align: 'left' },
      { text: 'Actions', value: 'action', sortable: false, align: 'left' }
    ],
    actionItem: {},
    actionIndex: -1,
    itemsPerPage: 10,
    footerProps: {
      'items-per-page-options': [5, 10, 15, -1]
    }
  }),
  created() {
    if (localStorage.popUpItemsPerPage) {
      this.itemsPerPage = parseInt(localStorage.popUpItemsPerPage);
    }
  },
  computed: {
    items() {
      return this.$store.state.popUp.items.map((item) => {
        item.goLiveDateTime4Display = new Date(item.goLiveDateTime).toLocaleString();
        item.audience4Display = popUpAudienceTypes.find((type) => type.code === item.popUpAudienceType).name;

        if (item.pages) {
          item.pages.forEach((page) => {
            var popUpType = popUpTypes.find((type) => type.code === page.popUpType);

            page.popUpType4Display = popUpType ? popUpType.name : '';
          });
        }

        item.pages.sort((a, b) => a.pageNumber - b.pageNumber);

        return item;
      });
    }
  },
  watch: {
    addEditDialog(val) {
      val || this.close();
    },
    itemsPerPage(newItemsPerPage) {
      localStorage.popUpItemsPerPage = newItemsPerPage.toString();
    }
  },
  mounted() {
    this.actionItem = this.defaultActionItem();
  },
  methods: {
    defaultActionItem() {
      return {
        pages: [{ selections: [] }]
      };
    },
    setActionItem(data) {
      this.actionItem = data;
      this.actionIndex = this.items.findIndex((item) => item.settingKey === data.settingKey);
    },
    clearActionItem() {
      this.actionItem = this.defaultActionItem();
      this.actionIndex = -1;
    },
    editItem(data) {
      this.setActionItem(data);
      this.addEditDialog = true;
    },
    newItem() {
      this.addEditDialog = true;
    },
    close() {
      this.addEditDialog = false;
      this.clearActionItem();
    },
    deleteItemYesAction() {
      this.$store.dispatch(storeActions.popUp.delete, this.actionItem)
        .then(() => {
          this.$snotify.info('PopUp deleted');
        })
        .catch((err) => {
          this.$log.error(err);
          this.$snotify.error('Error deleting PopUp. Error Message : ' + err.response?.data);
        });
      this.$snotify.remove();
      this.clearActionItem();
    },
    deleteItemNoAction() {
      this.$snotify.remove();
    },
    deleteItem(data) {
      this.setActionItem(data);
      this.$snotify.confirm('Are you sure you want to delete this PopUp?', {
        buttons: [
          { text: 'Yes', action: this.deleteItemYesAction, bold: false },
          { text: 'No', action: this.deleteItemNoAction }
        ]
      });
    },
    customSort(items, index, isDesc) {
      index = index[0];
      isDesc = isDesc[0];
      items.sort((a, b) => {
        if (index === 'goLiveDateTime4Display') {
          const aDate = new Date(a.goLiveDateTime);
          const bDate = new Date(b.goLiveDateTime);

          if (!isDesc) {
            return aDate.getTime() - bDate.getTime();
          } else {
            return bDate.getTime() - aDate.getTime();
          }
        } else if (index === 'pages[0].title') {
          if (!isDesc) {
            return a.pages[0].title.toLowerCase() < b.pages[0].title.toLowerCase() ? -1 : 1;
          } else {
            return b.pages[0].title.toLowerCase() < a.pages[0].title.toLowerCase() ? -1 : 1;
          }
        } else if (index === 'pages.length') {
          if (!isDesc) {
            return a.pages.length < b.pages.length ? -1 : 1;
          } else {
            return b.pages.length < a.pages.length ? -1 : 1;
          }
        } else {
          if (!isDesc) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    }
  }
};
