import axios from 'axios';
import Vue from 'vue';

const options = {
  baseURL: process.env.VUE_APP_API_URL
};

export default {
  popUp: {
    delete(data) {
      return axios.delete('/popup/' + data.popUpId, options).then((response) => {
        Vue.$log.debug('data received from api delete', response.data);
        return response.data;
      });
    },
    list() {
      return axios.get('/popup', options).then((response) => {
        Vue.$log.debug('data received from api list', response.data);
        return response.data;
      });
    },
    // list() {
    //   Vue.$log.debug('data received from api list', popupData);
    //   return asyncReturn(popupData, 10); // set to 1000 to wait 1 sec before returning posts, but will need to adjust e2e tests to wait an equal amount of time
    // },
    save(data) {
      return axios.post('/popup', data, options).then((response) => {
        Vue.$log.debug('data received from api save', response.data);
        return response.data;
      });
    },
    update(data) {
      return axios.put('/popup/' + data.popUpId, data, options).then((response) => {
        Vue.$log.debug('data received from api update', response.data);
        return response.data;
      });
    }
  },
  report: {
    list() {
      return axios.get('/report', options).then((response) => {
        Vue.$log.debug('data received from api list', response.data);
        return response.data;
      });
    },
    refresh(data) {
      return axios.get('/report/popup/' + data.popUpId, options).then((response) => {
        Vue.$log.debug('data received from api refresh', response.data);
        return response.data;
      });
    },
    getDownloadUrl(id) {
      Vue.$log.debug('About to call for URL: ', id);
      return axios.get('/report/popup/' + id + '/download', options).then((response) => {
        Vue.$log.debug('data received from api refresh', response.data);
        return response.data;
      });
    }
  },
  company: {
    listEnabled() {
      return axios.get('/enabledCompany', options).then((response) => {
        Vue.$log.debug('data received from api list', response.data);
        return response.data;
      });
    },
    listAll() {
      return axios.get('/allCompany', options).then((response) => {
        Vue.$log.debug('data received from api list', response.data);
        return response.data;
      });
    },
    update(data) {
      return axios.put('/company/' + data.companyId, data, options).then((response) => {
        Vue.$log.debug('data received from api update', response.data);
        return response.data;
      });
    }
  }
};
