import { popUpModuleActions } from '@/store/modules/popup/store-popup-types';
import { reportModuleActions } from '@/store/modules/report/store-report-types';
import { companyModuleActions } from '@/store/modules/company/store-company-types';

const popUpNamespace = 'popUp/';
const reportNamespace = 'report/';
const companyNamespace = 'company/';

export const storeActions = {
  popUp: {
    create: popUpNamespace + popUpModuleActions.create,
    delete: popUpNamespace + popUpModuleActions.delete,
    update: popUpNamespace + popUpModuleActions.update,
    list: popUpNamespace + popUpModuleActions.list
  },
  report: {
    list: reportNamespace + reportModuleActions.list,
    refresh: reportNamespace + reportModuleActions.refresh
  },
  company: {
    listEnabled: companyNamespace + companyModuleActions.listEnabled,
    listAll: companyNamespace + companyModuleActions.listAll,
    update: companyNamespace + companyModuleActions.update
  }
};
