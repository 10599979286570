import { storeActions } from '@/store/store-types';
import downloadUtility from '@/services/downloadUtility';
import moment from 'moment';

export default {
  data: () => ({
    showMobileSearch: false,
    search: '',
    headers: [
      { text: '', value: 'data-table-expand' },
      { text: 'Id', value: 'popUpId', align: 'left' },
      { text: 'Title*', value: 'pages[0].title', align: 'left' },
      { text: 'Popup Date', value: 'popUpDateTimeForDisplay', align: 'left' },
      { text: 'Report Refresh Date', value: 'reportDateTimeForDisplay', align: 'left' },
      { text: 'Actions', value: 'action', sortable: false, align: 'left' }
    ],
    mobileHeaders: [
      { text: 'Id', value: 'popUpId', align: 'left' },
      { text: 'Title*', value: 'pages[0].title', align: 'left' },
      { text: 'Popup Date', value: 'popUpDateTimeForDisplay', align: 'left' },
      { text: 'Report Refresh Date', value: 'reportDateTimeForDisplay', align: 'left' },
      { text: 'Actions', value: 'action', sortable: false, align: 'left' }
    ],
    actionItem: {},
    actionIndex: -1,
    isRefreshingReport: false,
    okRefreshStatus: 'OK',
    badRefreshStatus: 'BAD',
    emptyRefreshStatus: 'EMPTY',
    itemsPerPage: 10,
    footerProps: {
      'items-per-page-options': [5, 10, 15, -1]
    }
  }),
  computed: {
    items() {
      return this.$store.state.report.items.map((item) => {
        item.popUpDateTimeForDisplay = new Date(item.popUpDateTime).toLocaleString();
        item.reportDateTimeForDisplay = !this.isNullOrWhitespace(item.reportDateTime) ? new Date(item.reportDateTime).toLocaleString() : 'N/A';
        return item;
      });
    }
  },
  created() {
    if (localStorage.reportItemsPerPage) {
      this.itemsPerPage = parseInt(localStorage.reportItemsPerPage);
    }
  },
  mounted() {
    this.actionItem = this.defaultActionItem();
  },
  methods: {
    defaultActionItem() {
      return {
        selections: []
      };
    },
    setActionItem(data) {
      this.actionItem = data;
      this.actionIndex = this.items.findIndex((item) => item.settingKey === data.settingKey);
    },
    clearActionItem() {
      this.actionItem = this.defaultActionItem();
      this.actionIndex = -1;
    },
    refreshReport(data) {
      if (this.isRefreshingReport) {
        return;
      }
      this.isRefreshingReport = true;
      this.setActionItem(data);
      this.$snotify.async(
        'Please wait while we gather the "' + data.pages[0].title + '" report',
        'Refreshing...',
        () =>
          new Promise((resolve) => {
            var startDate = moment(new Date());
            this.requestReportRegeneration().then((success) => {
              if (success) {
                this.getUpdatedReport(data.popUpId, startDate).then((status) => {
                  if (status === this.okRefreshStatus) {
                    this.clearActionItem();
                    this.isRefreshingReport = false;
                    resolve({
                      title: 'Complete',
                      body: 'Refreshed the "' + data.pages[0].title + '" report',
                      config: {
                        closeOnClick: true,
                        timeout: 5000,
                        showProgressBar: true
                      }
                    });
                  } else {
                    this.clearActionItem();
                    this.isRefreshingReport = false;
                    resolve({
                      title: 'Failed',
                      body: 'Refresh failed. You might not be authenticated anymore. Try refreshing. See console for details.',
                      config: {
                        closeOnClick: true,
                        timeout: 5000,
                        showProgressBar: true,
                        type: 'error'
                      }
                    });
                  }
                });
              } else {
                this.clearActionItem();
                this.isRefreshingReport = false;
                resolve({
                  title: 'Failed',
                  body: 'Refresh failed. You might not be authenticated anymore. Try refreshing. See console for details.',
                  config: {
                    closeOnClick: true,
                    timeout: 5000,
                    showProgressBar: true,
                    type: 'error'
                  }
                });
              }
            });
          })
      );
    },
    downloadReport(data) {
      if (data.isReportGenerated) {
        downloadUtility.getDownloadUrl(data.popUpId).then((url) => {
          return new Promise((resolve) => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.send();
            xhr.onload = function() {
              const blob = new Blob([this.responseText], { type: 'text/csv' });
              const link = document.createElement('a');
              const reportName = data.pages[0].title + ' - ' + new Date(data.reportDateTime).toLocaleString() + '.csv';
              link.href = URL.createObjectURL(blob);
              link.download = reportName;
              link.click();
              URL.revokeObjectURL(link.href);
              resolve(reportName);
            };
          });
        });
      }
    },
    requestReportRegeneration() {
      return new Promise((resolve) => {
        this.$store
          .dispatch(storeActions.report.refresh, this.actionItem)
          .then(() => {
            resolve(true);
          })
          .catch((err) => {
            this.$log.error(err);
            resolve(false);
          });
      });
    },
    getUpdatedReport(needle, startDate) {
      var intervalId;

      return new Promise((resolve) => {
        intervalId = setInterval(() => {
          this.poll(needle, startDate).then((status) => {
            if (status === this.okRefreshStatus || status === this.badRefreshStatus) {
              clearInterval(intervalId);
              resolve(status);
            }

            if (moment(new Date()).isAfter(moment(startDate).add(5, 'minutes'))) {
              clearInterval(intervalId);
              resolve(this.badRefreshStatus);
            }
          });
        }, 10000);
      });
    },
    poll(needle, startDate) {
      return new Promise((resolve) => {
        try {
          this.$store
            .dispatch(storeActions.report.list)
            .then(() => {
              var found = this.items.find((i) => i.popUpId === needle);

              if (found) {
                if (found.isReportGenerated && !this.isNullOrWhitespace(found.reportDateTime)) {
                  if (moment(found.reportDateTime).isSameOrAfter(startDate)) {
                    resolve(this.okRefreshStatus);
                  }
                }
              } else {
                resolve(this.badRefreshStatus);
              }

              resolve(this.emptyRefreshStatus);
            })
            .catch((err) => {
              this.$log.error(err);
              resolve(this.badRefreshStatus);
            });
        } catch (err) {
          resolve(this.badRefreshStatus);
        }
      });
    },
    isNullOrWhitespace(str) {
      return str === null || /^\s*$/.test(str);
    },
    isInArray(needle, haystack) {
      return haystack.indexOf(needle) > -1;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    removeFromArray(target, arr) {
      var index = arr.indexOf(target);

      if (index > -1) {
        arr.splice(index, 1);
      }

      return arr;
    },
    customSort(items, index, isDesc) {
      index = index[0];
      isDesc = isDesc[0];
      items.sort((a, b) => {
        if (index === 'popUpDateTimeForDisplay') {
          return this.sortDateTime(a.popUpDateTime, b.popUpDateTime, isDesc);
        } else if (index === 'reportDateTimeForDisplay') {
          return this.sortDateTime(a.reportDateTime, b.reportDateTime, isDesc);
        } else if (index === 'pages[0].title') {
          if (!isDesc) {
            return a.pages[0].title.toLowerCase() < b.pages[0].title.toLowerCase() ? -1 : 1;
          } else {
            return b.pages[0].title.toLowerCase() < a.pages[0].title.toLowerCase() ? -1 : 1;
          }
        } else {
          if (!isDesc) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
    sortDateTime(obj1, obj2, isDesc) {
      var aDate = obj1 ? new Date(obj1) : new Date();
      var bDate = obj2 ? new Date(obj2) : new Date();

      if (!isDesc) {
        return aDate.getTime() - bDate.getTime();
      } else {
        return bDate.getTime() - aDate.getTime();
      }
    }
  },
  watch: {
    itemsPerPage(newItemsPerPage) {
      localStorage.reportItemsPerPage = newItemsPerPage;
    }
  }
};
