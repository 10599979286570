import client from 'api-client';
import Vue from 'vue';

export default {
  getDownloadUrl: async (popUpId) => {
    try {
      if (popUpId) {
        const res = await client.report.getDownloadUrl(popUpId);
        return res.url;
      }
    } catch (err) {
      Vue.$log.error('Error Retrieving URL', ErrorEvent);
    }
  }
};
