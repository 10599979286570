export default {
  name: 'ColorPickerComponent',
  props: {
    color: {
      required: true
    },
    pageNumber: {
      required: true
    }
  },
  data: () => ({
    colorPicked: null,
    colorMenu: false
  }),
  watch: {
    color(val) {
      if (val) {
        this.updatePickerWithColor();
      }
    }
  },
  methods: {
    updatePickerWithColor() {
      this.colorPicked = this.color;
    },
    updateColor() {
      this.$emit('updated', { colorPicked: this.colorPicked, pageNumber: this.pageNumber });
      this.colorMenu = false;
    }
  }
};
