import AuthService from '@/auth/auth-service';

export default async (to, from, next) => {
  if (typeof to.meta.auth !== 'boolean' && !Array.isArray(to.meta.auth)) {
    throw new Error(`route meta.auth is not in expected format (boolean or array): ${to.meta.auth}`);
  }

  if (to.meta.auth === false) {
    return next();
  } else {
    const isAuthenticated = await AuthService.isAuthenticated();

    if (isAuthenticated) {
      const isAuthorized = await AuthService.isAuthorized(to.meta.auth);
      if (isAuthorized) {
        return next();
      } else {
        return next({ name: 'forbidden' });
      }
    } else {
      await AuthService.loginWithRedirect({
        appState: { targetUrl: to.fullPath }
      });
    }
  }
};
