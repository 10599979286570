export default {
  name: 'DateTimePickerComponent',
  props: {
    dateTime: {
      required: true
    }
  },
  data: () => ({
    datePickerDate: null,
    timePickerTime: null,
    dateTimeMenu: false,
    dateTimeTab: null,
    today: new Date(Date.now()).toISOString().slice(0, 10)
  }),
  watch: {
    dateTimeMenu(val) {
      if (val) {
        this.updatePickersWithDateTime(this.dateTime);
      }
    }
  },
  methods: {
    convertDateTimeToDatePickerFormat(dateTime) {
      // dateTime = "04/10/2020 03:11 pm"; return "2020-04-10"
      if (!dateTime) return null;
      const date = dateTime.slice(0, 10);
      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    convertDateTimeToTimePickerFormat(dateTime) {
      // dateTime = "04/10/2020 03:11 pm"; return "15:11"
      if (!dateTime) return null;
      let time = dateTime.trim().slice(-1 * (dateTime.length - 11));
      time = time.trim();
      const meridiem = time.slice(-2);
      let [hour, min] = time.slice(0, 5).split(':');

      if (meridiem.toLowerCase() === 'pm') {
        if (hour !== '12') {
          hour = (parseInt(hour, 10) + 12).toString();
        }
      }
      return `${hour}:${min}`;
    },
    updatePickersWithDateTime() {
      // dateTime = "04/10/2020 03:11 pm"; datePickerDate = "2020-04-10", timePickerTime = "15:11"
      if (this.dateTime && this.dateTime.length !== 19) {
        this.datePickerDate = null;
        this.timePickerTime = null;
      } else {
        this.datePickerDate = this.convertDateTimeToDatePickerFormat(this.dateTime);
        this.timePickerTime = this.convertDateTimeToTimePickerFormat(this.dateTime);
      }
    },
    updateDateTime() {
      // datePickerDate = "2020-04-10", timePickerTime = "15:11"; emit "04/10/2020 03:11 pm"
      const date = this.convertDatePickerToDateTimeFormat(this.datePickerDate);
      const time = this.convertTimePickerToDateTimeFormat(this.timePickerTime);
      const newDateTime = this.buildDateTime(date, time);
      this.$emit('updated', newDateTime);
      this.dateTimeMenu = false;
    },
    buildDateTime(date, time) {
      return time ? `${date} ${time}` : date;
    },
    convertDatePickerToDateTimeFormat(date) {
      // date = "2020-04-10"; return "04/10/2020"
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
    convertTimePickerToDateTimeFormat(time) {
      // time = "15:11"; return "03:11 PM"
      if (!time) return null;
      let [hour, min] = time.split(':');
      let meridiem = 'AM';
      let hourInt = parseInt(hour, 10);
      if (hourInt >= 12) {
        meridiem = 'PM';
        if (hourInt !== 12) {
          hourInt = hourInt - 12;
          hour = hourInt.toString();
        }
      }
      return `${hour.padStart(2, '0')}:${min.padStart(2, '0')} ${meridiem}`;
    }
  }
};
