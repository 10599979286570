import 'core-js/stable'; // ie11 fix
import 'regenerator-runtime/runtime'; // ie11 fix

import 'nprogress/nprogress.css';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import Snotify, { SnotifyPosition } from 'vue-snotify';
import VueLogger from 'vuejs-logger';
import VueCookies from 'vue-cookies';
import DateTimePicker from 'vuetify-datetime-picker';
import axios from 'axios';
import store from '@/store/store';
import AuthService from '@/auth/auth-service';

const options = {
  toast: {
    position: SnotifyPosition.centerCenter
  }
};

Vue.use(VueCookies);
Vue.use(Snotify, options);
Vue.use(VueLogger, {
  isEnabled: true,
  logLevel: process.env.VUE_APP_LOG_LEVEL || 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
});
Vue.use(DateTimePicker);
Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  store,
  async created() {
    axios.interceptors.request.use(
      async (config) => {
        const token = await AuthService.getTokenSilently();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  render: (h) => h(App)
}).$mount('#app');
