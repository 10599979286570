<template>
  <v-app id="popUpAdminUI">
    <v-navigation-drawer v-model="drawer" app clipped>
      <v-list dense>
        <v-list-item to="/popups">
          <v-list-item-action>
            <v-icon>mdi-clipboard-list-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>PopUps</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/reports">
          <v-list-item-action>
            <v-icon>mdi-file-chart</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Reports</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
           <v-list-item to="/companies">
          <v-list-item-action>
            <v-icon>business</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Companies</v-list-item-title>
          </v-list-item-content>
    </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>PopUp Admin UI</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon @click="onClickTheme">{{ $vuetify.theme.dark ? 'wb_sunny' : 'brightness_3' }}</v-icon>
    </v-app-bar>

    <v-content>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-content>

    <template>
      <div id="preFooter" style="padding-top: 50px;">&nbsp;</div>
    </template>

    <vue-snotify></vue-snotify>
  </v-app>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  data: () => ({
    drawer: null
  }),
  methods: {
    onClickTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$cookies.set('theme', this.$vuetify.theme.dark);
    }
  },
  mounted() {
    const script = document.createElement('script');
    script.src = 'https://cdn-internal.webcms.foc.zone/launchkit/components/loader.min.js';
    script.dataset.lkscCache = 'hour';
    script.dataset.lkscEnvironment = 'prod';
    script.dataset.lkscComponents = 'footerBasicInternal_RocketEnterpriseTech';
    script.defer = 'defer';
    document.getElementsByTagName('main')[0].appendChild(script);
  }
});
</script>
<style src="vue-snotify/styles/material.css"></style>
<style src="./App.css"></style>
