import { validationMixin } from 'vuelidate';
import { required, requiredIf, maxLength, minValue, integer, url, minLength } from 'vuelidate/lib/validators';
import { storeActions } from '@/store/store-types';
import { forOwn as _forOwn } from 'lodash';
import sjv from 'simple-js-validator';
import moment from 'moment';
import { mask } from 'vue-the-mask';
import AuthService from '@/auth/auth-service';

import ColorPickerComponent from '@/components/color-picker/color-picker.vue';
import DateTimePickerComponent from '@/components/date-time-picker/date-time-picker.vue';
import PopupViewerComponent from '@/components/popup-viewer/popup-viewer.vue';

// todo, move this to service/vuex
const popUpTypes = [
  {
    code: 1,
    name: 'RATING'
  },
  {
    code: 2,
    name: 'MULTIPLE'
  },
  {
    code: 3,
    name: 'TEXTAREA'
  },
  {
    code: 4,
    name: 'LINK'
  },
  {
    code: 5,
    name: 'MULTISELECT'
  },
  {
    code: 6,
    name: 'MAINTENANCE'
  }
];

const popUpAudienceTypes = [
  {
    code: 1,
    name: 'AllTeamMembers'
  },
  {
    code: 2,
    name: 'SpecificTeamMembers'
  },
  {
    code: 3,
    name: 'LeadersOnly'
  },
  {
    code: 4,
    name: 'SpecificCompanies'
  }
];

const FIELD_MAX_LENGTHS = {
  title: 25,
  question: 250,
  primaryButtonText: 28,
  selections: 40,
  popUpDateTime: 19
};

const requiredIfMultipleChoice = (vm, index, pageIndex) => {
  // true = invalid; false/no return = valid
  if (vm.pages[pageIndex].popUpType4Display === 'MULTIPLE' || vm.pages[pageIndex].popUpType4Display === 'MULTISELECT') {
    return sjv.isEmpty(vm.pages[pageIndex].selections[index]);
  }
};

const cannotBeEmptyIfNextIsPopulated = (vm, index, pageIndex) => {
  // true = invalid; false/no return = valid
  if (sjv.isNotEmpty(vm.pages[pageIndex].selections[index + 1])) {
    return sjv.isEmpty(vm.pages[pageIndex].selections[index]);
  }
};

const requiredDateTimeFormat = (popUpDateTime) => {
  // true = invalid; false/no return = valid
  if (sjv.isEmpty(popUpDateTime) || popUpDateTime.length !== 19) {
    return true;
  }
};

export default {
  name: 'PopupAddEditComponent',
  components: {
    'color-picker-component': ColorPickerComponent,
    'date-time-picker-component': DateTimePickerComponent,
    'popup-viewer-component': PopupViewerComponent
  },
  mixins: [validationMixin],
  directives: { mask },
  props: {
    actionItem: {
      type: Object,
      required: true
    },
    actionIndex: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    showMobileViewer: false,
    tabs: null,
    numberOfQuestions: [1, 2, 3, 4],
    selectedQuestion: 1,
    stepper: 1,
    viewModel: {},
    popUpDateTimeMask: '##/##/#### ##:## SS',
    maxLengths: FIELD_MAX_LENGTHS,
    multipleChoices: [0, 1, 2, 3, 4],
    possibleCompanies: [],
    isAllCompaniesSelected: false
  }),
  validations: {
    stepperGroup1: ['viewModel.pages'],
    stepperGroup2: ['viewModel.popUpDateTime', 'viewModel.numberOfReminders', 'viewModel.frequencyOfRemindersInHours'],
    stepperGroup3: ['viewModel.selectedCompanies'],
    stepperGroup4: ['viewModel.popUpFilterAudience', 'viewModel.selectedCommonIds'],
    viewModel: {
      numberOfReminders: {
        required,
        minValue: minValue(0),
        integer
      },
      frequencyOfRemindersInHours: {
        required,
        minValue: minValue(0),
        integer
      },
      popUpDateTime: {
        mustBeDateTime: requiredIf(function() {
          return requiredDateTimeFormat(this.viewModel.popUpDateTime);
        })
      },
      popUpFilterAudience: {
        required
      },
      selectedCommonIds: {
        required: requiredIf(function() {
          return this.viewModel.popUpFilterAudience === 'SpecificTeamMembers';
        })
      },
      pages: {
        required,
        minLength: minLength(1),
        $each: {
          popUpType4Display: {
            required: requiredIf(function(page) {
              if (page.popUpType4Display === 'LINK' || page.popUpType4Display === 'MAINTENANCE') {
                page.primaryButtonText = 'Go';
              } else {
                page.primaryButtonText = 'Submit';
              }
              return this.popUpHasMorePagesThan(page.pageNumber - 1);
            })
          },
          color: {
            required: requiredIf(function(page) {
              return this.popUpHasMorePagesThan(page.pageNumber - 1);
            })
          },
          primaryButtonText: {
            required: requiredIf(function(page) {
              return this.popUpHasMorePagesThan(page.pageNumber - 1);
            }),
            maxLength: maxLength(FIELD_MAX_LENGTHS.primaryButtonText)
          },
          title: {
            required: requiredIf(function(page) {
              return this.popUpHasMorePagesThan(page.pageNumber - 1);
            }),
            maxLength: maxLength(FIELD_MAX_LENGTHS.title)
          },
          question: {
            required: requiredIf(function(page) {
              return this.popUpHasMorePagesThan(page.pageNumber - 1);
            }),
            maxLength: maxLength(FIELD_MAX_LENGTHS.question)
          },
          selections_0: {
            requiredIfMultiple: requiredIf(function(page) {
              return this.popUpHasMorePagesThan(page.pageNumber - 1) && requiredIfMultipleChoice(this.viewModel, 0, page.pageNumber - 1);
            }),
            maxLength: maxLength(FIELD_MAX_LENGTHS.selections)
          },
          selections_1: {
            requiredIfMultiple: requiredIf(function(page) {
              return this.popUpHasMorePagesThan(page.pageNumber - 1) && requiredIfMultipleChoice(this.viewModel, 1, page.pageNumber - 1);
            }),
            maxLength: maxLength(FIELD_MAX_LENGTHS.selections)
          },
          selections_2: {
            cannotBeEmptyIfNextIsNot: requiredIf(function(page) {
              return this.popUpHasMorePagesThan(page.pageNumber - 1) && cannotBeEmptyIfNextIsPopulated(this.viewModel, 2, page.pageNumber - 1);
            }),
            maxLength: maxLength(FIELD_MAX_LENGTHS.selections)
          },
          selections_3: {
            cannotBeEmptyIfNextIsNot: requiredIf(function(page) {
              return this.popUpHasMorePagesThan(page.pageNumber - 1) && cannotBeEmptyIfNextIsPopulated(this.viewModel, 3, page.pageNumber - 1);
            }),
            maxLength: maxLength(FIELD_MAX_LENGTHS.selections)
          },
          selections_4: {
            maxLength: maxLength(FIELD_MAX_LENGTHS.selections)
          },
          link: {
            requiredIfLink: requiredIf(function(page) {
              // true = invalid; false/no return = valid
              if (this.popUpHasMorePagesThan(page.pageNumber - 1) && (this.viewModel.pages[0].popUpType4Display === 'LINK' || this.viewModel.pages[0].popUpType4Display === 'MAINTENANCE')) {
                return sjv.isEmpty(this.viewModel.pages[0].link);
              }
            }),
            url
          }
        }
      },
      selectedCompanies: {
        required,
        minLength: minLength(1)
      }
    }
  },
  created() {
    // this maps the viewModel from action item for the initial one
    this.viewModel = this.mapToVM(this.actionItem);
    this.setPossibleCompanies();
  },
  updated() {
    this.selectedQuestion = this.viewModel.pages ? this.viewModel.pages.length : 1;
  },
  computed: {
    dialogTitle() {
      return this.actionIndex === -1 ? 'New PopUp' : 'Edit PopUp (' + this.viewModel.popUpId + ')';
    },
    requiredCompanies() {
      return this.$store.state.company.items;
    }
  },
  watch: {
    actionItem(val) {
      // this maps all the changed to actionItem after the initial creation
      this.viewModel = this.mapToVM(val);
      this.setPossibleCompanies();
    },
    selectedQuestion(val) {
      if (!this.viewModel.pages) return;
      var currentQuestionCount = this.viewModel.pages.length;

      if (val < currentQuestionCount) {
        for (var i = currentQuestionCount; i > val; i--) {
          this.viewModel.pages.pop();
        }
      } else if (val > currentQuestionCount) {
        for (var j = currentQuestionCount; j < val; j++) {
          var question = {
            pageNumber: j + 1,
            popUpType4Display: 'RATING',
            color: '#000000',
            primaryButtonText: 'Submit',
            title: '',
            question: '',
            selections: []
          };

          this.viewModel.pages.push(question);
        }
      }
    },
    possibleCompanies: {
      deep: true,
      handler: function(newPossibleCompanies) {
        this.viewModel.selectedCompanies = [];
        var self = this;
        newPossibleCompanies.forEach(poc => {
          if (poc.isChecked) {
            self.viewModel.selectedCompanies.push({
              companyId: poc.companyId,
              companyName: poc.companyName,
              isEnabled: true
            });
          }
        });
        const isAllChecked = newPossibleCompanies.every(function(company) {
          return company.isChecked;
        });
        this.isAllCompaniesSelected = isAllChecked;
      }
    }
  },
  methods: {
    onClickNextStep() {
      // validate the step "coming from" which is in the stepper
      const stepperGroupKey = 'stepperGroup' + this.stepper.toString();
      if (this.$v[stepperGroupKey].$invalid) {
        _forOwn(this.$v[stepperGroupKey], function(value, key) {
          if (!key.startsWith('$')) {
            value.$touch();
          }
        });
      }
    },
    onUpdatedDateTime(data) {
      this.viewModel.popUpDateTime = data;
    },
    onUpdatedEndDateTime(data) {
      this.viewModel.popUpEndDateTime = data;
    },
    onUpdatedColor(data) {
      this.viewModel.pages[data.pageNumber - 1].color = data.colorPicked.hex || data.colorPicked;
    },
    pageErrors(key, pageNumber) {
      if (this.$v.viewModel.pages.$each[pageNumber - 1]) {
        return this.getPageErrors(this.$v.viewModel.pages.$each[pageNumber - 1], key);
      }
    },
    errors(key) {
      var errors = [];
      errors = errors.concat(this.getViewModelErrors(key));
      return errors;
    },
    mapFromVM(vm) { // This is when sending to API
      const item = { ...vm };

      // date handling => vm expects 'MM/DD/YYYY hh:mm a' and db expects iso8601
      item.goLiveDateTime = moment(item.popUpDateTime, 'MM/DD/YYYY hh:mm a').toISOString();
      item.goLiveEndDateTime = moment(item.popUpEndDateTime, 'MM/DD/YYYY hh:mm a').toISOString();

      delete item.popUpDateTime;
      delete item.popUpEndDateTime;
      delete item.goLiveDateTime4Display; // list adds this

      item.pages.forEach((page) => {
        // link handling => vm requires in link field, db requires it in selections
        if (page.popUpType4Display === 'LINK' || page.popUpType4Display === 'MAINTENANCE') {
          page.selections = [page.link];
        }

        delete page.link;

        // selections handling => vm requires empty array
        page.selections = page.selections ? page.selections : [];
        page.selections = page.selections.filter((x) => sjv.isNotEmpty(x)); // remove any empty strings

        // popUpType handling => vm expects code (RATING) and db expects number (1)
        page.popUpType = popUpTypes.find((type) => type.name === page.popUpType4Display).code;

        delete page.popUpType4Display;
      });

      item.popUpAudienceType = popUpAudienceTypes.find((type) => type.name === item.popUpFilterAudience).code;

      if (item.popUpFilterAudience === 'SpecificTeamMembers') {
        item.commonIds = item.selectedCommonIds ? item.selectedCommonIds.split('\n') : [];
        item.commonIds = item.commonIds.filter((x) => sjv.isNotEmpty(x)); // remove any empty strings
      }

      item.companies = this.possibleCompanies.filter((possibleCompany) => possibleCompany.isChecked).map(function(possibleCompany) {
        return { companyId: possibleCompany.companyId, companyName: possibleCompany.companyName, isEnabled: true };
      });

      delete item.selectedCommonIds;
      return item;
    },
    mapToVM(item) { // This is when recieving from API
      const vm = { ...item };

      // date handling => vm expects 'MM/DD/YYYY hh:mm a' and db expects iso8601
      vm.popUpDateTime = vm.goLiveDateTime
        ? moment(vm.goLiveDateTime).format('MM/DD/YYYY hh:mm a')
        : moment()
          .add(7, 'days')
          .format('MM/DD/YYYY hh:mm a');

      vm.popUpEndDateTime = vm.goLiveEndDateTime ? moment(vm.goLiveEndDateTime).format('MM/DD/YYYY hh:mm a') : '';

      if (!vm.popUpId) {
        var defaultPage = {
          pageNumber: 1,
          popUpType4Display: 'RATING',
          color: '#000000',
          primaryButtonText: 'Submit',
          title: '',
          question: '',
          selections: []
        };

        vm.pages = [defaultPage];
      } else {
        vm.pages.forEach((page) => {
          // link handling => vm requires in link field, not selections
          if (page.popUpType4Display === 'LINK' || page.popUpType4Display === 'MAINTENANCE') {
            page.link = page.selections && page.selections[0] ? page.selections[0] : null;
            page.selections = [];
          }

          // selections handling => vm requires empty array
          page.selections = page.selections ? page.selections : [];

          // color handling => vm requires a valid color
          page.color = page.color ? page.color : '#000000';
        });
      }
      if (vm.pages) {
        vm.pages.forEach((page) => {
          if (page.popUpType4Display === 'LINK' || page.popUpType4Display === 'MAINTENANCE') {
            page.primaryButtonText = 'Go';
          }
        });
      }

      if (vm.popUpAudienceType) {
        vm.popUpFilterAudience = popUpAudienceTypes.find((type) => type.code === vm.popUpAudienceType).name;
      }

      if (vm.popUpFilterAudience === 'SpecificTeamMembers') {
        vm.selectedCommonIds = vm.commonIds ? vm.commonIds.join('\n') : [];
      }

      vm.selectedCompanies = vm.companies ? vm.companies : [];

      // reminder handling => defaulting to 0
      vm.numberOfReminders = vm.numberOfReminders ? vm.numberOfReminders : '0';
      vm.frequencyOfRemindersInHours = vm.frequencyOfRemindersInHours ? vm.frequencyOfRemindersInHours : '0';

      // delete unused fields
      delete vm.secondaryButtonText;
      delete vm.dateOfPopUp;
      delete vm.created;
      delete vm.updated;
      delete vm.isActive;
      return vm;
    },
    async save() {
      this.$v.$touch();

      // update username on save (currently can't be done in the api)
      const user = await AuthService.getUser();
      this.viewModel.username = user?.['https://ql.custom.openid.com/samaccountname'] ?? 'unknown';

      const itemToSave = this.mapFromVM(this.viewModel);

      if (!this.$v.$invalid) {
        if (this.actionIndex > -1) {
          this.$store
            .dispatch(storeActions.popUp.update, itemToSave)
            .then(() => {
              this.$snotify.info('PopUp updated');
            })
            .catch((err) => {
              this.$log.error(err);
              this.$snotify.error('Error updating PopUp. Error Message : ' + err.response?.data);
            });
        } else {
          this.$store
            .dispatch(storeActions.popUp.create, itemToSave)
            .then(() => {
              this.$snotify.info('PopUp created');
            })
            .catch((err) => {
              this.$log.error(err);
              this.$snotify.error('Error creating PopUp. Error Message: ' + err.response?.data);
            });
        }
        this.close();
      }
    },
    close() {
      this.$v.$reset();
      this.viewModel = {};
      this.selectedCompanies = [];
      this.stepper = '1';
      this.$emit('close');
    },
    popUpHasMorePagesThan(pageNumber) {
      return this.viewModel.pages.length > pageNumber;
    },
    getViewModelErrors(key) {
      var errors = [];

      switch (key) {
        case 'color':
        case 'popUpType4Display':
        case 'numberOfReminders':
          break;
        case 'frequencyOfRemindersInHours':
          if (!this.$v.viewModel[key].$dirty) return errors;
          !this.$v.viewModel[key].required && errors.push('Field is required');
          !this.$v.viewModel[key].minValue && errors.push('Field must be a positive number');
          !this.$v.viewModel[key].integer && errors.push('Field must be a number');
          break;
        case 'title':
        case 'question':
        case 'primaryButtonText':
          break;
        case 'popUpDateTime':
          if (!this.$v.viewModel[key].$dirty) return errors;
          !this.$v.viewModel[key].mustBeDateTime && errors.push('Field must be exactly 19 chars');
          break;
        case 'selections_0':
        case 'selections_1':
        case 'selections_2':
        case 'selections_3':
        case 'selections_4':
        case 'link':
          break;
        case 'selectedCommonIds':
          if (this.viewModel.popUpFilterAudience === 'SpecificTeamMembers') {
            if (!this.$v.viewModel[key].$dirty) return errors;
            !this.$v.viewModel[key].required && errors.push('Field is required');
          }
          break;
        case 'selectedCompanies':
          if (!this.$v.viewModel[key].$dirty) return errors;
          !this.$v.viewModel[key].required && errors.push('Select at least one company');
          break;
        case 'popUpFilterAudience':
          if (!this.$v.viewModel[key].$dirty) return errors;
          !this.$v.viewModel[key].required && errors.push('Field is required');
          break;
        default:
          throw new Error(`key (${key}) not handled by validation error logic`);
      }
      return errors;
    },
    getPageErrors(page, key) {
      var errors = [];

      if (!page) return;

      switch (key) {
        case 'color':
        case 'popUpType4Display':
        case 'numberOfReminders':
        case 'frequencyOfRemindersInHours':
          if (!page[key].$dirty) return errors;
          !page[key].required && errors.push('Field is required');
          break;
        case 'title':
        case 'question':
        case 'primaryButtonText':
          if (!page[key].$dirty) return errors;
          !page[key].required && errors.push('Field is required');
          !page[key].maxLength && errors.push('Field is too long');
          break;
        case 'popUpDateTime':
        case 'selections_0':
        case 'selections_1':
          if (!page[key].$dirty) return errors;
          !page[key].requiredIfMultiple && errors.push('Must be at least 2 selections if multiple');
          !page[key].maxLength && errors.push('Field is too long');
          break;
        case 'selections_2':
        case 'selections_3':
          if (!page[key].$dirty) return errors;
          !page[key].cannotBeEmptyIfNextIsNot && errors.push('Cannot be empty if next selection is populated');
          !page[key].maxLength && errors.push('Field is too long');
          break;
        case 'selections_4':
          if (!page[key].$dirty) return errors;
          !page[key].maxLength && errors.push('Field is too long');
          break;
        case 'link':
          if (page[key] && !page[key].$dirty) return errors;
          if (page[key] && page[key].$dirty) {
            !page[key].requiredIfLink && errors.push('Field is required if link');
            !page[key].url && errors.push('Must be valid url');
          }
          break;
        case 'selectedCommonIds':
        case 'selectedCompanies':
        case 'popUpFilterAudience':
          break;
        default:
          throw new Error(`key (${key}) not handled by validation error logic`);
      }
      return errors;
    },
    selectAllCompanies(val) {
      var self = this;
      this.viewModel.selectedCompanies = [];
      this.possibleCompanies.forEach(company => {
        company.isChecked = val;
        if (val) {
          self.viewModel.selectedCompanies.push({
            companyId: company.companyId,
            companyName: company.companyName,
            isEnabled: true
          });
        }
      });
    },
    setPossibleCompanies() {
      this.possibleCompanies = this.sortObj(this.requiredCompanies.map(function(company) { return { companyId: company.companyId, companyName: company.companyName, isChecked: false }; }), 'companyName');
      var self = this;
      this.viewModel.selectedCompanies.forEach(function(selectedCompany) {
        var possibleCompany = self.possibleCompanies.find((co) => co.companyId === selectedCompany.companyId);

        if (possibleCompany) {
          possibleCompany.isChecked = true;
        }
      });
    },
    sortObj(list, key) {
      function compare(a, b) {
        a = a[key];
        b = b[key];
        var type = (typeof (a) === 'string' ||
          typeof (b) === 'string') ? 'string' : 'number';
        var result;
        if (type === 'string') result = a.localeCompare(b);
        else result = a - b;
        return result;
      }
      return list.sort(compare);
    }
  }
};
