import Vue from 'vue';
import client from 'api-client';
import { companyModuleActions } from './store-company-types';

const state = {
  items: []
};

const actions = {
  [companyModuleActions.listEnabled]({ commit }) {
    return client.company.listEnabled().then((res) => commit('setEnabledCompanies', res));
  },
  [companyModuleActions.listAll]({ commit }) {
    return client.company.listAll().then((res) => commit('setAllCompanies', res));
  },
  [companyModuleActions.update]({ commit }, data) {
    return client.company.update(data).then((res) => commit('updateCompanies', res));
  }
};

const mutations = {
  setEnabledCompanies(statePassThru, data) {
    statePassThru.items = data;
  },
  setAllCompanies(statePassThru, data) {
    statePassThru.items = data;
  },
  updateCompanies(statePassThru, data) {
    var index = statePassThru.items.findIndex((item) => item.companyId === data.companyId);
    Vue.set(statePassThru.items, index, data);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
