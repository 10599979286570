import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import VueCookies from 'vue-cookies';

Vue.use(Vuetify);

const cookiedTheme = VueCookies.get('theme');
const theme = cookiedTheme === 'true';

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    dark: theme,
    themes: {
      dark: {
        primary: '#543691',
        accent: '#F1F0F4',
        secondary: '#A593D0',
        success: '#4CAF50',
        info: '#60E0FF',
        warning: '#FB8C00',
        error: '#FF5252'
      },
      light: { // from https://bootswatch.com/pulse/ theme
        primary: '#543691',
        accent: '#F1F0F4',
        secondary: '#A593D0',
        success: '#53B661',
        info: '#419BD7',
        warning: '#E3A541',
        error: '#EA4C44'
      }
    }
  }
});

// color from allin
// primary: '#613889',
// accent: '#9686DC',
// secondary: '#2F63A9',
