import { storeActions } from '@/store/store-types';

export default {
  data: () => ({
    showMobileSearch: false,
    search: '',
    headers: [
      { text: 'Company Id', value: 'companyId', align: 'left' },
      { text: 'Company Name', value: 'companyName', align: 'left' },
      { text: 'Enabled', value: 'isEnabled', align: 'left', sortable: false }
    ],
    mobileHeaders: [
      { text: 'Company Id', value: 'companyId', align: 'left' },
      { text: 'Company Name', value: 'companyName', align: 'left' },
      { text: 'Enabled', value: 'isEnabled', align: 'left', sortable: false }
    ],
    dropDownSelections: ['Unfiltered', 'On', 'Off'],
    selected: 'Unfiltered',
    itemsPerPage: 10,
    footerProps: {
      'items-per-page-options': [5, 10, 15, -1]
    }
  }),
  computed: {
    items() {
      if (this.selected === 'On') {
        return this.$store.state.company.items.filter((item) => item.isEnabled);
      } else if (this.selected === 'Off') {
        return this.$store.state.company.items.filter((item) => !item.isEnabled);
      }
      return this.$store.state.company.items;
    }
  },
  created() {
    if (localStorage.companyItemsPerPage) {
      this.itemsPerPage = parseInt(localStorage.companyItemsPerPage);
    }
  },
  methods: {
    customFilter(value, search) {
      search = search.toString().toLowerCase();
      value = this.formatValue(value);

      return value != null && search != null && typeof value === 'string' && value.indexOf(search) !== -1;
    },
    saveCompany(companyId, isEnabled) {
      const company = this.items.find(c => c.companyId === companyId);
      company.isEnabled = isEnabled;

      this.$store
        .dispatch(storeActions.company.update, company)
        .then(() => { })
        .catch((err) => {
          this.$log.error(err);
          this.$snotify.error('Error creating PopUp. Error Message: ' + err.response?.data);
        });
    },
    formatValue(value) {
      if (value != null) {
        switch (typeof value) {
          case 'string':
            value = value.toString().toLowerCase();
            break;
          case 'boolean':
            value = value ? 'on' : 'off';
            break;
          default:
            break;
        }
        return value;
      }
    }
  },
  watch: {
    itemsPerPage(newItemsPerPage) {
      localStorage.companyItemsPerPage = newItemsPerPage.toString();
    }
  }
};
