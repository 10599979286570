import Vue from 'vue';
import Vuex from 'vuex';
import StoreModulePopUp from './modules/popup/store-popup';
import StoreModuleReport from './modules/report/store-report';
import StoreModuleCompany from './modules/company/store-company';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    popUp: StoreModulePopUp,
    report: StoreModuleReport,
    company: StoreModuleCompany
  },
  state: {
    packageVersion: process.env.PACKAGE_JSON_VERSION || '0.0.0'
  },
  getters: {
    appVersion: (state) => {
      return state.packageVersion;
    }
  }
});
